import Vue from 'vue'
import Vuex from 'vuex'
import devices from '../apis/devices'
import billing from '../apis/billing'

Vue.use(Vuex)

export default new Vuex.Store({
  strict: true,
  state: {
    options: [],
    plans: [],
    subscriptions: [],
    desiredSubscriptionChanges: [],
    ownedDevices: []
  },
  getters: {
    plan: state => (planId: string) => state.plans.find(x => x.id === planId),
    options: state => (deviceType: string) => state.options.filter(x => deviceType === x.deviceType),
    subscriptions: state => state.subscriptions,
    currentSubscription: state => (deviceSerialNumber: string, optionId: string) =>
      state.subscriptions.find(x => x.device === deviceSerialNumber && x.option === optionId),
    desiredSubscriptionChanges: state => state.desiredSubscriptionChanges,
    desiredSubscriptionChange: state => (deviceSerialNumber: string, optionId: string) =>
      state.desiredSubscriptionChanges.find(x => x.device === deviceSerialNumber && x.option === optionId),
    ownedDevices: state => state.ownedDevices,
    currentIncludedOptionId: state => (deviceSerialNumber: string) => state.plans
      .filter(x => x.included)
      .map(x => ({
        plan: x,
        subscription: state.desiredSubscriptionChanges
          .concat(state.subscriptions)
          .filter(x => x.device === deviceSerialNumber)
          .find(y => y.plan === x.id)
      }))
      .find(({ subscription }) => subscription)
      ?.subscription?.option
  },
  mutations: {
    EDIT_DESIRED_SUBSCRIPTION (state, subscriptions) {
      state.desiredSubscriptionChanges = state.desiredSubscriptionChanges
        .filter(x => !subscriptions.some(y => x.device === y.device && x.option === y.option))
        .concat(subscriptions)
    },
    ADD_OWNED_DEVICES (state, devices) {
      const newDevicesBySerialNumber = {}
      devices.forEach(x => { newDevicesBySerialNumber[x.serialNumber] = x })
      state.ownedDevices = state.ownedDevices.filter(x => !newDevicesBySerialNumber[x.serialNumber]).concat(devices)
    },
    REPLACE_PLANS (state, plans) {
      state.plans = plans
    },
    REPLACE_OPTIONS (state, options) {
      state.options = options
    },
    REPLACE_SUBSCRIPTIONS (state, subscriptions) {
      state.desiredSubscriptionChanges = []
      state.subscriptions = subscriptions
    }
  },
  actions: {
    addDesiredSubscription (context, { deviceSerialNumber, optionId, planId }) {
      return new Promise((resolve, reject) => {
        const newSubscriptions = []

        if (context.getters.plan(planId)?.included) {
          const currentOptionPlanId = (context.getters.desiredSubscriptionChange(deviceSerialNumber, optionId) || context.getters.currentSubscription(deviceSerialNumber, optionId))?.plan
          const currentIncludedOptionId = context.getters.currentIncludedOptionId(deviceSerialNumber)

          if (currentIncludedOptionId) {
            const currentIncludedOption = context.state.options.find(x => x.id === currentIncludedOptionId)
            if (!currentOptionPlanId || currentIncludedOption.plans.includes(currentOptionPlanId)) {
              newSubscriptions.push({ device: deviceSerialNumber, option: currentIncludedOptionId, plan: currentOptionPlanId })
            } else {
              return reject(new Error('incompatible-included-plan-switch'))
            }
          }
        }
        newSubscriptions.push({ device: deviceSerialNumber, option: optionId, plan: planId })
        context.commit('EDIT_DESIRED_SUBSCRIPTION', newSubscriptions)
        return resolve()
      })
    },
    async addDeviceToAccount (context, { device }) {
      const response = await devices.takeDeviceOwnership(device)
      context.commit('ADD_OWNED_DEVICES', [response.data])
    },
    async refreshOwnedDevices (context) {
      const ownedDevices = (await devices.ownedDevices()).data
      context.commit('ADD_OWNED_DEVICES', ownedDevices)
      return ownedDevices
    },
    async refreshPlans (context) {
      const response = await billing.getAvailablePlans()
      context.commit('REPLACE_PLANS', response.data)
    },
    async refreshOptions (context) {
      const response = await billing.getAvailableOptions()
      context.commit('REPLACE_OPTIONS', response.data)
    },
    async refreshSubscriptions (context, subscriptions) {
      if (!subscriptions) {
        const response = await billing.getCurrentSubscriptions()
        subscriptions = response.data
      }
      context.commit('REPLACE_SUBSCRIPTIONS', subscriptions)
    }
  },
  modules: {
  }
})
