import { Api } from './api'

type Subscription = {
  device: string;
  option: string;
  plan: string;
  expiration?: Date;
}

enum Period {
  Month
}

interface Plan {
  id: string;
  description: string;
  price: number;
  period: Period;
  included: boolean;
}

enum DeviceType {
  vsta,
  vstd
}

interface Option {
  id: string;
  name: string;
  deviceType: DeviceType;
  plans: Array<string>;
}

interface Response<T> {
  data: T;
  status: number;
  statusText: string;
  headers: object;
  config: object;
  request: object;
}

export default {
  clientToken: () => Api.post('billing/client-tokens'),
  getAvailablePlans (): Promise<Response<Array<Plan>>> { return Api.get('billing/plans') },
  getAvailableOptions (): Promise<Response<Array<Option>>> { return Api.get('billing/options') },
  getCurrentSubscriptions (): Promise<Response<Array<Subscription>>> { return Api.get('billing/subscriptions') },
  changePlans: (desiredSubscriptionChanges: Array<Subscription>, paymentMethodNonce: string) => Api.put('billing/subscriptions', { desiredSubscriptionChanges, paymentMethodNonce })
}
