import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from './i18n'
import vuetify from './plugins/vuetify'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import 'dayjs/locale/en'
import 'dayjs/locale/fr'
import { Auth0Plugin } from './auth'
import { domain, clientId, audience } from '../auth_config.json'

Vue.config.productionTip = false
dayjs.locale('fr')
dayjs.extend(relativeTime)

Vue.filter('fromNow', (date: Date) => {
  return dayjs(date).fromNow()
})

router.afterEach((to, _from) => {
  document.querySelector('meta[name="apple-itunes-app"]').setAttribute('app-argument', `vigilsmarttrailer://${to.fullPath}`)
})

Vue.use(Auth0Plugin, {
  domain,
  clientId,
  audience,
  onRedirectCallback: appState => {
    router.push(
      appState && appState.targetUrl
        ? appState.targetUrl
        : window.location.pathname
    )
  }
})

new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: h => h(App)
}).$mount('#app')
