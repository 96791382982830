<template>
  <div>
    <slot v-if="$auth.loading" name="loading">
      <v-row class="text-center">
        <v-col>
          <v-progress-circular indeterminate/>
        </v-col>
      </v-row>
    </slot>
    <slot v-else-if="user"/>
    <slot v-else name="unauthenticated">
      <v-row class="text-center">
        <v-col v-if="!vstAuthAvailable" cols="12">
          <v-btn @click="login">{{$t('connect')}}</v-btn>
        </v-col>
      </v-row>
    </slot>
  </div>
</template>

<script>
export default {
  name: 'Authenticated',
  methods: {
    login () {
      this.$auth.loginWithRedirect({
        appState: { targetUrl: this.$route.fullPath }
      })
    }
  },
  computed: {
    vstAuthAvailable () { return this.$auth.vstAvailable },
    user () { return this.$auth.user },
    auth () { return this.$auth }
  }
}
</script>

<style lang="sass" scoped>

</style>

<i18n>
{
  "en": {
    "connect": "Connect"
  },
  "fr": {
    "connect": "Se connecter"
  }

}
</i18n>
