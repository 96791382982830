<template>
  <div class="home">
    <Authenticated>
      <template #default>
        <v-row class="text-center">
          <v-col cols="12">
            <v-img :src="user.picture"
                  contain
                  max-height="128"/>
          </v-col>
          <v-col cols="12">
            <h1>{{user.name}}</h1>
          </v-col>
          <v-col cols="12">
            {{user.email}}
          </v-col>
          <v-col v-if="!vstAuthAvailable" cols="12">
            <v-btn @click="logout">Logout</v-btn>
          </v-col>
        </v-row>
      </template>
    </Authenticated>
  </div>
</template>

<script>
import { Component, Vue } from 'vue-property-decorator'
import Authenticated from '../auth/Authenticated.vue'

@Component({
  components: {
    Authenticated
  },
  methods: {
    logout () {
      this.$auth.logout({
        appState: { targetUrl: this.$route.fullPath }
      })
    }
  },
  computed: {
    vstAuthAvailable () { return this.$auth.vstAvailable },
    user () { return this.$auth.user }
  }
})
export default class Account extends Vue {}
</script>
